<template>
  <a-modal
    :visible="visible"
    @cancel="closeModal"
    :footer="null"
    class="request-news-sources-modal"
    :title="null"
  >
    <div class="request-news-sources-modal-container">
      <h1 class="request-news-sources-modal-container__title">
        Request Sources
      </h1>
      <request-news-source :requestType="'news source'" :emptyFields="emptyFields"></request-news-source>
    </div>
  </a-modal>
</template>

<script>
import RequestNewsSource from "./RequestNewsSource.vue";

export default {
  props: {
    visible: Boolean,
  },
  components: {
    RequestNewsSource,
  },
  emits: ["closeFeedUploadModal"],
  mounted(){
    this.emitter.on('closeNewsSourceModal', ()=>{
      this.closeModal()
    })
  },
  watch:{
    visible:{
      handler(val){
        if (!val){
          this.emitter.emit('clearFields')
        }
      }, immediate:true
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeRequestNewsSourceModal", false);
    },
  },
};
</script>

<style lang="scss">
.request-news-sources-modal {
  width: 52rem !important;
  top: 8rem !important;
  .ant-modal-content {
    .ant-modal-close {
      margin-top: 2rem !important;
      margin-right: 2rem !important;
    }
    .ant-modal-body {
      .request-news-sources-modal-container {
        &__title {
          font-size: 2.4rem;
          font-family: $font-primary-bold;
          line-height: normal;
          margin-bottom: 0;
          color: $color-black;
          padding: 2rem 2rem 1rem;
        }
        .request-news-source-container {
          padding: 0 2rem 2.5rem;
          .title {
            font-size: 1.6rem;
            line-height: 2rem;
            font-family: $font-primary;
            max-width: 40rem;
            max-width: 41rem;
            color: $color-black;
            opacity: 0.8;
          }
          .ant-form {
            .ant-form-item {
              .ant-form-item-control {
                .ant-form-item-control-input {
                  .ant-form-item-control-input-content {
                    label {
                      font-family: $font-primary;
                      font-size: 1.6rem;
                    }
                    .error {
                      font-size: 1.4rem;
                      margin-top: 0.5rem;
                    }
                    p,
                    input,
                    textarea {
                      font-size: 1.6rem;
                      line-height: normal;
                    }
                  }
                }
              }
            }
            .ant-btn {
              height: 4rem;
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
}
</style>
