<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    :header="null"
    width="54rem"
    class="all-news-sources-modal"
  >
    <div class="all-news-sources-modal-container">
      <div class="all-news-sources-modal-container__header">
        <h1>News Sources</h1>
      </div>
      <div class="all-news-sources-modal-container__body">
        <div class="all-news-sources-modal-container__body--grid">
          <template v-for="(country, index) in Object.keys(allSources)" :key="index">
            <div v-if="allSources[country].length > 0" class="all-news-sources-modal-container__body--grid--sources-col">
              <h1
                class="all-news-sources-modal-container__body--grid--sources-col--title"
              >
                {{ country }}
                <!-- <span v-if="country.color">({{ country.color }})</span> -->
              </h1>
              <div
                class="all-news-sources-modal-container__body--grid--sources-col--sources"
              >
              <template v-if="!isListing">
                <a-checkbox
                  v-for="(source, i) in allSources[country]"
                  :key="i"
                  v-model:checked="source.isChecked"
                >
                  {{ source?.source_name }}
                </a-checkbox>
              </template>
              <template v-else>
                <p v-for="(source, i) in allSources[country]"
                  :key="i" class="source-name">{{ source?.source_name }}</p>
              </template>
              </div>
            </div>
          </template>
        </div>
        <!-- <a-row :gutter="[18, 22]">
          <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="all-news-sources-modal-container__body--grid--sources-col">
              <h1
                class="all-news-sources-modal-container__body--grid--sources-col--title"
              >
                United States <span>(blue)</span>
              </h1>
              <div
                class="all-news-sources-modal-container__body--grid--sources-col--sources"
              >
                <a-checkbox v-model:checked="checked">CNN</a-checkbox>
                <a-checkbox v-model:checked="checked">Fox News</a-checkbox>
                <a-checkbox v-model:checked="checked">CNBC</a-checkbox>
              </div>
            </div>
          </a-col>
          <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="all-news-sources-modal-container__body--grid--sources-col">
              <h1
                class="all-news-sources-modal-container__body--grid--sources-col--title"
              >
                Russia
              </h1>
              <div
                class="all-news-sources-modal-container__body--grid--sources-col--sources"
              >
                <a-checkbox v-model:checked="checked">Russia Today</a-checkbox>
                <a-checkbox v-model:checked="checked">TASS</a-checkbox>
                <a-checkbox v-model:checked="checked">Sputnik News</a-checkbox>
              </div>
            </div>
          </a-col>
          <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="all-news-sources-modal-container__body--grid--sources-col">
              <h1
                class="all-news-sources-modal-container__body--grid--sources-col--title"
              >
                Pakistan
              </h1>
              <div
                class="all-news-sources-modal-container__body--grid--sources-col--sources"
              >
                <a-checkbox v-model:checked="checked">ARY News</a-checkbox>
                <a-checkbox v-model:checked="checked">Geo News</a-checkbox>
                <a-checkbox v-model:checked="checked">Dawn News</a-checkbox>
              </div>
            </div>
          </a-col>
          <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="all-news-sources-modal-container__body--grid--sources-col">
              <h1
                class="all-news-sources-modal-container__body--grid--sources-col--title"
              >
                United Kingdom
              </h1>
              <div
                class="all-news-sources-modal-container__body--grid--sources-col--sources"
              >
                <a-checkbox v-model:checked="checked">BBC News</a-checkbox>
                <a-checkbox v-model:checked="checked">Sky News</a-checkbox>
                <a-checkbox v-model:checked="checked">The Times</a-checkbox>
              </div>
            </div>
          </a-col>
        </a-row> -->
      </div>
      <div v-if="!isListing" class="all-news-sources-modal-container__footer">
        <a-button
          :loading="loader"
          class="all-news-sources-modal-container__footer--btn"
          @click.stop="addSources()"
        >
          Submit
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import  {mapActions, mapGetters} from "vuex"
export default {
  props: {
    visible: Boolean,
    isResearch:{
      type: Boolean,
      default: () => (false)
    },
    modalType:{
      type:String,
      default: () => ('include')
    },
    isListing:{
      type: Boolean,
      default: () =>(false)
    }
  },
  emits: ["close-modal"],
  data() {
    return {
      checked: {}, // Ensure it's an object
      loader: false,
      newsSources: [
        {
          name: "United States",
          color: "blue",
          sources: ["CNN", "Fox News", "CNBC"],
        },
        { name: "Russia", sources: ["Russia Today", "TASS", "Sputnik News"] },
        { name: "Pakistan", sources: ["ARY News", "Geo News", "Dawn News"] },
        {
          name: "United Kingdom",
          sources: ["BBC News", "Sky News", "The Times"],
        },
      ],
    };
  },
  computed:{
    ...mapGetters({
      sourcesByCountry: 'discover/sourcesByCountry',
      stateActiveTopicTab: 'discover/activeTopicTab',
      trendingNewsFilters: 'discover/trendingNewsFilters',
      insightSourcesByCountry: 'discover/insightSourcesByCountry',
      trendingResearchFilter: 'discover/trendingResearchFilter'
    }),
    allSources:{
      get(){
        const includedSources = this.isResearch ? this.getSelectedInsightIncludeSources : this.getSelectedIncludeSources
        const excludedSources = this.isResearch ? this.getSelectedInsightExcludeSources : this.getSelectedExcludeSources

        let sources = Object.groupBy(this.isResearch ? this.insightSourcesByCountry : this.sourcesByCountry, ({country})=>country)
        delete sources.undefined
        
        if (this.modalType === 'include' && this.visible){
          Object.keys(sources).forEach((key)=>{
            sources[key].forEach(source=>{
              source.isChecked = includedSources.find(existingSources=>existingSources.source_name ===  source.source_name) ?? false
            })
          })

          Object.entries(sources).forEach(([key, value])=>{
            sources[key] = value.filter(source=>!excludedSources.find(excludedSource=>excludedSource.source_name === source.source_name))
          })

        }else if (this.modalType === 'exclude' && this.visible){
          Object.keys(sources).forEach((key)=>{
            sources[key].forEach(source=>{
              source.isChecked = excludedSources.find(existingSources=>existingSources.source_name ===  source.source_name) ?? false
            })
          })

          Object.entries(sources).forEach(([key, value])=>{
            sources[key] = value.filter(source=>!includedSources.find(includedSource=>includedSource.source_name === source.source_name))
          })

        }
        return sources //return an object
      },
    },
    getSelectedIncludeSources: {
      get(){
        return this.stateActiveTopicTab === 'Trending news' ? this.trendingNewsFilters?.includedSources : this.$store.getters['discover/getIncludedSelectedSources'];
      }
    },
    getSelectedExcludeSources: {
      get(){
        return this.stateActiveTopicTab === 'Trending news' ? this.trendingNewsFilters?.excludedSources : this.$store.getters['discover/getExcludedSelectedSources'];
      }
    },
    getSelectedInsightIncludeSources: {
      get(){
        return this.stateActiveTopicTab === 'Trending news' ? this.trendingResearchFilter?.includedSources : this.$store.getters['discover/getInsightsIncludedSelectedSources'];
      },
    },
    getSelectedInsightExcludeSources: {
      get(){
        return this.stateActiveTopicTab === 'Trending news' ? this.trendingResearchFilter?.excludedSources : this.$store.getters['discover/getInsightsExcludedSelectedSources'];
      },
    },
  },
  methods: {
    ...mapActions({
      getSourcesByCountry: 'discover/getSourcesByCountry'
    }),
    closeModal() {
      this.$emit("close-modal");
    },
    addSources(){
      const selectedSources = Object.values(this.allSources).flatMap(source=>source).filter(source=>source.isChecked)

      if (this.modalType === 'include')
        this.$emit('includedSources', selectedSources)
      else if (this.modalType === 'exclude')
        this.$emit('excludedSources', selectedSources)
      
    this.closeModal()
      // console.log(selectedSources)
    }
  },
};
</script>

<style lang="scss">
.all-news-sources-modal {
  top: 8rem !important;
  .ant-modal-content {
    background-color: #f6f8ff;

    .ant-modal-close {
      margin: 1.9rem 2rem;
    }
    .ant-modal-body {
      padding: 0;
      .all-news-sources-modal-container {
        padding: 2.5rem 0;
        &__header {
          margin-bottom: 2.5rem;
          padding: 0 2.5rem;
          h1 {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            margin-bottom: 0;
            line-height: normal;
            color: $color-black;
          }
        }
        &__body {
          padding: 0 2.5rem;
          height: calc(100vh - 33rem);
          overflow-y: auto;
          overflow-x: hidden;
          &::-webkit-scrollbar {
            width: 0.4rem;
            height: 0.4rem;
          }
  
          &::-webkit-scrollbar-track {
            background-color: transparent;
            border-radius: 0.8rem;
          }
  
          &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            border-radius: 0.8rem;
          }
          &--grid {
            gap: 2rem;
            column-count: 2;
            &--sources-col {
              width: 100%;
              height: max-content;
              break-inside: avoid;
              display: inline-block;
              margin-bottom: 2rem;
              &--title {
                font-size: 1.8rem;
                line-height: 2.2rem;
                margin-bottom: 1rem;
                font-family: $font-primary-bold;
                color: $color-black;
                span {
                  font-size: 1.6rem;
                  line-height: normal;
                  opacity: 0.7;
                }
              }
              &--sources {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                .source-name {
                  font-size: 1.6rem;
                  line-height: normal;
                  font-family: $font-primary;
                  color: $color-black;
                  margin-bottom: 0;
                }
                .ant-checkbox-wrapper {
                  font-size: 1.6rem;
                  font-family: $font-primary;
                  width: fit-content;
                  margin-bottom: 0;
                  line-height: normal;
                  display: flex;
                  align-items: flex-start;
                  color: $color-black;
                  margin-left: 0;
                  .ant-checkbox {
                    .ant-checkbox-inner {
                      border: 1px solid $color-dark-grey-5;
                      height: 1.8rem;
                      width: 1.8rem;
                      border-radius: 0;
                      &::after {
                        width: 0.6rem;
                        height: 0.9rem;
                      }
                    }

                    .ant-checkbox-input {
                      width: fit-content;
                      height: 100% !important;
                    }
                  }
                  .ant-checkbox + span {
                    padding: 0 0.8rem;
                    margin-top: -0.2rem;
                    opacity: 0.8;
                  }
                  .ant-checkbox-checked {
                    .ant-checkbox-inner {
                      background-color: $color-primary;
                      border-color: $color-primary;
                      height: 1.8rem;
                      width: 1.8rem;
                      border-radius: 0;
                    }
                  }
                }
              }
            }
            &--error-msg {
              font-size: 1.4rem;
              font-family: $font-primary;
              color: red;
              margin: 0.8rem 0 0;
            }
          }
        }
        &__footer {
          padding: 0 2.5rem;
          &--btn {
            margin-top: 3.5rem;
            outline: none;
            border: none;
            background-color: $color-primary;
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            color: $color-white;
            height: 4rem;
            width: 8rem;
            display: block;
            margin-left: auto;
            border-radius: 5px !important;
            cursor: pointer;
            padding: 0 !important;
            .anticon {
              .anticon-spin {
                font-size: 2rem;
                color: $color-primary;
              }
            }
          }
        }
      }
    }
  }
}
</style>
