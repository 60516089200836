<template>
  <a-drawer
    placement="right"
    :closable="true"
    :visible="visibleDrawer"
    wrapClassName="sources-filter-drawer"
    width="46.5rem"
    title="Search Filters"
    @close="$emit('close-drawer')"
  >
    <TopicFilters />
  </a-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TopicFilters from "./TopicFilters.vue";
export default {
  emits: ["close-drawer"],
  props: {
    visibleDrawer: Boolean,
  },
  components: {
    TopicFilters
  },
  data() {
    return {
      activeKey: '1',
      closeDropDown: false,
      isPdbNullify: false,
      newsExactMatch:'',
      researchExactMatch:''
    }
  },
  computed: {
    ...mapGetters({
      selectedModule: 'discover/selectedModule',
      activeTopicTab: 'discover/activeTopicTab',
      getTopicDetails: 'discover/getTopicDetails'
    }),
    getSelectedNewIncludeSources() {
      return this.$store.getters['discover/getIncludedSelectedSources']; 
    },
    getSelectedNewsExcludeSources() {
      return this.$store.getters['discover/getExcludedSelectedSources'];
    },
    getSelectedInsightsIncludeSources() {
      return this.$store.getters['discover/getInsightsIncludedSelectedSources'];
    },
    getSelectedInsightsExcludeSources() {
      return this.$store.getters['discover/getInsightsExcludedSelectedSources'];
    },
    // PDBIncludeKeywords() {
    //   return this.$store.getters['discover/getPDBIncludedKeywords']; 
    // },
    // PDBExcludeKeywords() {
    //   return this.$store.getters['discover/getPDBExcludedKeywords'];
    // },
    // insightsIncludeKeywords() {
    //   return this.$store.getters['discover/getInsightsIncludedKeywords'];
    // },
    // insightsExcludeKeywords() {
    //   return this.$store.getters['discover/getInsightsExcludedKeywords'];
    // },
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if (e.key == 'Escape') {
        if (this.activeKey === '1' &&
          Object.keys(this.getSelectedNewIncludeSources).length > 0 ||
          Object.keys(this.getSelectedNewsExcludeSources).length > 0) {
          this.searchNewsArticles();
        } else if (this.activeKey === '2' &&
          Object.keys(this.getSelectedInsightsIncludeSources).length > 0 ||
          Object.keys(this.getSelectedInsightsExcludeSources).length > 0) {
          this.searchInsightsArticles();
        }
      }
    });
  },
  watch:{
    selectedModule:{
      handler(val){
          this.activeKey = val === 'Trending News' || val === 'news-tab' ? '1' :'2'
      },
      immediate:true
    }
  },
  methods: {
    ...mapActions({
      setTrendingNewFilters: 'discover/setTrendingNewFilters',
      setTrendingResearchFilters: 'discover/setTrendingResearchFilters',
      emptyTrendingNewsFilters: 'discover/emptyTrendingNewsFilters',
      setActiveTopic: 'discover/setActiveTopic'
    }),
    async searchNewsArticles() {

      this.closeFilterDrawer();
      console.log("TOPIC", this.getTopicDetails)
      // this.$store.commit("discover/SET_ARTICLES_PAGE_OFFSET", 0);
      // this.setNewsExactValue()
      // await this.$store.dispatch("discover/searchArticles");
    },
    async searchInsightsArticles() {
      this.closeFilterDrawer();
      this.$store.commit("discover/SET_INSIGHTS_PAGE_OFFSET", 0);
      this.setResearchExactValue()
      await this.$store.dispatch("discover/searchInsights");
    },
    clearInsightsFilters() {
      this.emitter.emit("clearInsightsFilters", true);
      this.emitter.emit()
      if (this.activeTopicTab === 'Trending news') {
        this.emptyTrendingNewsFilters('trending-research')
      }
      else{
        this.$store.commit("discover/SET_INSIGHTS_INCLUDED_SOURCES", "");
        this.$store.commit("discover/SET_INSIGHTS_EXCLUDED_SOURCES", "");
      }
      this.researchExactMatch = ''
      this.$store.commit('discover/SET_FILTERS_COUNT', 0);
      this.$store.dispatch("discover/getAllInsightsSources");
      this.searchInsightsArticles();
    },
    clearNewsFilters() {
      this.emitter.emit("clearNewsFilters", true);
      if (this.activeTopicTab === 'Trending news') {
        this.emptyTrendingNewsFilters()
      }
      else{
        this.$store.commit("discover/SET_INCLUDED_SOURCES", "");
        this.$store.commit("discover/SET_EXCLUDED_SOURCES", "");
      }
      this.$store.commit('discover/SET_FILTERS_COUNT', 0);
      this.$store.dispatch("discover/getAllSources");
      this.newsExactMatch = ''
      this.searchNewsArticles();
    },
    closeFilterDrawer() {
      this.emitter.emit("closeFilterSideDrawer", false);
    },
    getPDBIncludeKeywords(val) {
      const keywords = val.map(words => `${words}`).join(' AND ');
      this.activeTopicTab === 'Trending news' ? this.setTrendingNewFilters({includedKeywords: keywords}) : this.$store.commit('discover/PDB_INCLUDED_KEYWORDS', keywords);
    },
    getPDBExcludeKeywords(val) {
      const keywords = val.map(word => `-${word}`).join(' AND ');
      this.activeTopicTab === 'Trending news' ? this.setTrendingNewFilters({excludedKeywords: keywords}) : this.$store.commit('discover/PDB_EXCLUDED_KEYWORDS', keywords);
    },
    getInsightsIncludeKeywords(val) {
      const keywords = val.map(words => `${words}`).join(' AND ');
      this.activeTopicTab === 'Trending news' ? this.setTrendingResearchFilters({includedKeywords: keywords}) : this.$store.commit('discover/INSIGHTS_INCLUDED_KEYWORDS', keywords);
    },
    getInsightsExcludeKeywords(val) {
      const keywords = val.map(country => `-${country}`).join(' AND ');
      this.activeTopicTab === 'Trending news' ? this.setTrendingResearchFilters({excludedKeywords: keywords}) : this.$store.commit('discover/INSIGHTS_EXCLUDED_KEYWORDS', keywords);
    },
    setNewsExactValue(){
      if (this.newsExactMatch)
      this.setTrendingNewFilters({exactKeyword: `"${this.newsExactMatch}"`})
      // this.activeTopicTab === 'Trending news' ? this.setTrendingNewFilters({exactKeyword: `"${this.newsExactMatch}"`}) : this.$store.commit('discover/INSIGHTS_EXCLUDED_KEYWORDS', keywords);
    },
    setResearchExactValue(){
      if (this.researchExactMatch)
      this.setTrendingResearchFilters({exactKeyword: `"${this.researchExactMatch}"`})
      // this.activeTopicTab === 'Trending news' ? this.setTrendingResearchFilters({exactKeyword: `"${this.researchExactMatch}"`}) : this.$store.commit('discover/INSIGHTS_EXCLUDED_KEYWORDS', keywords);
    }
  }
};
</script>

<style lang="scss">
.sources-filter-drawer {
  @include reset-css;
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      overflow: hidden;
      .ant-drawer-wrapper-body {
        overflow: hidden;
        .ant-drawer-header {
          padding: 3.3rem 2.5rem 4rem;
          border: none;
          .ant-drawer-title {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            opacity: 1;
            color: $color-black;
          }
          .ant-drawer-close {
            width: 2rem;
            height: 2rem;
            line-height: normal;
            right: 2.5rem;
            top: 3.3rem;
            color: $color-black;
            opacity: 1;
          }
        }
        .ant-drawer-body {
          height: 100%;
          padding: 0;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
