<template>
  <div class="pdb-news-sources-date-filters">
    <a-date-picker
      @change="setDatesFilters()"
      placeholder="Start Date"
      class="start-date"
      v-model:value="newsContentStartDate"
      :disabledDate="disableNewsContentStartDate"
    />
    <a-date-picker
      @change="setDatesFilters()"
      placeholder="End Date"
      class="end-date"
      v-model:value="newsContentEndDate"
      :disabledDate="disableNewsContentEndDate"
    />
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
export default {
  mounted() {
    this.emitter.on("clearNewsFilters", () => {
      this.newsContentStartDate = null,
      this.newsContentEndDate = null,
      this.$store.commit("discover/SET_START_DATE", null);
      this.$store.commit("discover/SET_END_DATE", null);
      this.setTrendingNewFilters({startDate:'', endDate:''})
    });
  },
  data() {
    return {
      newsContentStartDate: null,
      newsContentEndDate: null,
    };
  },
  computed:{
    ...mapGetters({
      activeTopicTab: 'discover/activeTopicTab',
      trendingNewsFilters: 'discover/trendingNewsFilters'
    }),
  },
  watch:{
    activeTopicTab:{
      handler(val){
        if (val === 'Trending news'){
        this.newsContentStartDate = this.trendingNewsFilters?.startDate ?? ''
        this.newsContentEndDate = this.trendingNewsFilters?.endDate ?? ''
        }else{
          this.newsContentStartDate = this.$store.getters["discover/getStartDate"] ?? ''
          this.newsContentEndDate = this.$store.getters["discover/getEndDate"] ?? ''
        }
      }, immediate:true
    }
  },
  methods: {
    ...mapActions({
      setTrendingNewFilters: 'discover/setTrendingNewFilters'
    }),
    disableNewsContentStartDate(date) {
      if (!this.newsContentEndDate || !this.newsContentEndDate.startOf) {
        return false;
      }
      return date > this.newsContentEndDate.startOf("day");
    },
    disableNewsContentEndDate(date) {
      if (!this.newsContentStartDate || !this.newsContentStartDate.startOf) {
        return false;
      }
      return date < this.newsContentStartDate.startOf("day");
    },
    setDateFormat(date) {
      date =
        typeof date == "object" && (date !== "") & (date !== null)
          ? date._d.getFullYear() +
            `-${(date._d.getMonth() + 1).toString().length === 1 ? "0" : ""}` +
            (date._d.getMonth() + 1) +
            `-${date._d.getDate().toString().length === 1 ? "0" : ""}` +
            date._d.getDate()
          : date;
      return date;
    },
    setDatesFilters() {
      const startDate = this.setDateFormat(this.newsContentStartDate);
      const endDate = this.setDateFormat(this.newsContentEndDate);

      if (this.activeTopicTab === 'Trending news'){
        this.setTrendingNewFilters({startDate, endDate})
      }
      else{
        this.$store.commit("discover/SET_START_DATE", startDate);
        this.$store.commit("discover/SET_END_DATE", endDate);
      }
    },
  },
};
</script>

<style lang="scss">
.pdb-news-sources-date-filters {
  .ant-calendar-picker {
    width: 100%;
    margin-bottom: 1.5rem;
    position: relative;
    > div {
      position: relative;
      display: flex;
      align-items: center;
      border: 1px solid #c5c6cc;
      border-radius: 5px;
      .ant-calendar-picker-input {
        height: 4rem;
        font-size: 1.4rem;
        border: none !important;
        font-family: $font-primary;
        box-shadow: none;
      }
      .anticon {
        position: inherit;
        transform: none;
        top: 0 !important;
        background-color: transparent;
      }
      .anticon-close-circle {
        right: -0.3rem;
        width: 1.9rem;
        height: 1.9rem;
      }
    }
  }
}
</style>
