<template>
    <div class="pdb-reserch-date-filters">
        <a-date-picker
          @change="setDatesFilters()"
          placeholder="Start Date"
          class="start-date"
          v-model:value="insightsStartDate"
          :disabledDate="disableInsightsStartDate"
        />
        <a-date-picker
          @change="setDatesFilters()"
          placeholder="End Date"
          class="end-date"
          v-model:value="insightsEndDate"
          :disabledDate="disableInsightsEndDate"                     
        />
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
export default{
  mounted(){
    this.emitter.on('clearInsightsFilters', ()=>{
        this.insightsStartDate = ""
        this.insightsEndDate = ""
        if (this.activeTopicTab === 'Trending news'){
          this.setTrendingResearchFilters({startDate:'', endDate:''})
        }else{
          this.$store.commit("discover/SET_INSIGHTS_START_DATE", "");
          this.$store.commit("discover/SET_INSIGHTS_END_DATE", "");
        }
    })
  },
    data() {
     return {
       insightsStartDate: "",
       insightsEndDate: "",
     }
    },
    computed:{
      ...mapGetters({
        activeTopicTab: 'discover/activeTopicTab',
        getInsightsStartDate: 'discover/getInsightsStartDate',
        getInsightsEndDate: 'discover/getInsightsEndDate',
        trendingResearchFilter: 'discover/trendingResearchFilter'
      }),
    },
    watch:{
    activeTopicTab:{
      handler(val){
        if (val === 'Trending news'){
          this.insightsStartDate = this.trendingResearchFilter?.startDate ?? ''
          this.insightsEndDate = this.trendingResearchFilter?.endDate ?? ''
        }else{
          this.insightsStartDate = this.getInsightsStartDate ?? ''
          this.insightsEndDate = this.getInsightsEndDate ?? ''
        }
      }, immediate:true
    }
  },
    methods: {
      ...mapActions({
        setTrendingResearchFilters: 'discover/setTrendingResearchFilters'
      }),
      disableInsightsStartDate(date){
      if (!this.insightsEndDate) {
        return false;
      }
      return date > this.insightsEndDate.startOf('day');
     },
     disableInsightsEndDate(date){
      if (!this.insightsStartDate) {
        return false;
      }
      return date < this.insightsStartDate.startOf('day');
     },
     setDateFormat(date) {
      date =
        typeof date == "object" && (date !== "") & (date !== null)
          ? date._d.getFullYear() +
            `-${(date._d.getMonth() + 1).toString().length===1? '0': ''}` +
            (date._d.getMonth() + 1) +
            `-${date._d.getDate().toString().length === 1 ? '0' :  ''}` +
            date._d.getDate()
          : date;
      return date;
     },
     setDatesFilters(){
      const insightsStartDate = this.setDateFormat(this.insightsStartDate);
      const insightsEndDate = this.setDateFormat(this.insightsEndDate);
      if (this.activeTopicTab === 'Trending news'){
        this.setTrendingResearchFilters({startDate: insightsStartDate, endDate: insightsEndDate})
      }else{
        this.$store.commit("discover/SET_INSIGHTS_START_DATE", insightsStartDate);
        this.$store.commit("discover/SET_INSIGHTS_END_DATE", insightsEndDate);
      }
     },
    }
}

</script>

<style lang="scss">
.pdb-reserch-date-filters {
  .ant-calendar-picker {
    width: 100%;
    margin-bottom: 1.5rem;
    .ant-input {
      height: 4rem;
      font-size: 1.4rem;
      border: 1px solid #c5c6cc;
      font-family: $font-primary;
    }
    .anticon {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>