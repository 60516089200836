<template>
    <!-- <div class="apply-filter">
        <div class="apply-filter__left">
            <div class="title">
                <p>Page {{ pageNumber }}-{{ totalPages }} of {{ results.value }}</p>
                <p v-if="searchTopic">
                    results for <span> "{{ searchTopic }}"</span>
                </p>
            </div>
        </div> 
        <div class="apply-filter__right">
            <div class="buttons">
                <button @click.stop="openSourcesFilters()" :class="{ 'filter-active': visibleSourcesFilter }"
                    class="filter-btn filter-apply-btn">
                    <svg width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.00008 16C7.71675 16 7.47925 15.9042 7.28758 15.7125C7.09591 15.5208 7.00008 15.2833 7.00008 15V9L1.20008 1.6C0.95008 1.26667 0.91258 0.916667 1.08758 0.55C1.26258 0.183333 1.56675 0 2.00008 0H16.0001C16.4334 0 16.7376 0.183333 16.9126 0.55C17.0876 0.916667 17.0501 1.26667 16.8001 1.6L11.0001 9V15C11.0001 15.2833 10.9042 15.5208 10.7126 15.7125C10.5209 15.9042 10.2834 16 10.0001 16H8.00008ZM9.00008 8.3L13.9501 2H4.05008L9.00008 8.3Z" />
                    </svg>

                    <p>: {{ filtersCounts }} Filters</p>
                </button>
                <div class="sort-container" @click.stop="openSortFilters()">
                    <div class="sort">
                        <img src="@/assets/icons/sort.svg" alt="icon" />
                        <p>: Sort</p>
                        <svg width="12" height="8" viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg" class="arrow">
                            <path d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z" />
                        </svg>
                    </div>
                    <div class="sort-dropdown" v-if="visibleSortFilter">
                        <p @click="applySortFilter('recency')" :class="{ 'select-sort': selectSortDate }">Date</p>
                        <p @click="applySortFilter('relevancy')" :class="{ 'select-sort': selectSortRelevance }">Relevance
                        </p>
                    </div>
                </div>
            </div>
            <div class="mobile-buttons-container">
                <div class="mobile-buttons">
                    <button @click.stop="openSourcesDrawer()" :class="{ 'filter-active': visibleSourcesFilter }"
                        class="filter-btn filter-apply-btn">
                        <svg width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.00008 16C7.71675 16 7.47925 15.9042 7.28758 15.7125C7.09591 15.5208 7.00008 15.2833 7.00008 15V9L1.20008 1.6C0.95008 1.26667 0.91258 0.916667 1.08758 0.55C1.26258 0.183333 1.56675 0 2.00008 0H16.0001C16.4334 0 16.7376 0.183333 16.9126 0.55C17.0876 0.916667 17.0501 1.26667 16.8001 1.6L11.0001 9V15C11.0001 15.2833 10.9042 15.5208 10.7126 15.7125C10.5209 15.9042 10.2834 16 10.0001 16H8.00008ZM9.00008 8.3L13.9501 2H4.05008L9.00008 8.3Z" />
                        </svg>
                    </button> 
                    <div class="sort-container" @click.stop="showSortDrawer()">
                        <div class="sort">
                            <img src="@/assets/icons/sort.svg" alt="icon" />
                        </div>
                        <a-drawer :title="false" placement="bottom" :closable="false" :visible="visibleSortDrawer"
                            class="sort-drawer">
                            <div class="sort-drawer-container">
                                <div class="sort-drawer-container__header">
                                    <p>Sort</p>
                                    <i class="icon icon-add_plus" @click="closeSortDrawer()"></i>
                                </div>
                                <div class="sort-drawer-container__body">
                                    <button @click="applySortFilter('recency')">Date</button>
                                    <button @click="applySortFilter('relevancy')">Relevance</button>
                                </div>
                            </div>
                        </a-drawer>
                    </div>
                </div>
            </div>
      </div> 
     </div> -->

    <a-drawer :title="false" placement="bottom" :closable="false" :visible="visibleSortDrawer"
        class="sort-drawer">
        <div class="sort-drawer-container">
            <div class="sort-drawer-container__header">
                <p>Sort</p>
                <i class="icon icon-add_plus" @click="closeSortDrawer()"></i>
            </div>
            <div class="sort-drawer-container__body">
                <button @click="applySortFilter('recency')">Date</button>
                <button @click="applySortFilter('relevancy')">Relevance</button>
            </div>
        </div>
    </a-drawer>
    <!-- <discover-source-filter v-if="visibleSourcesFilter"></discover-source-filter> -->
    <sources-filters-side-drawer @closeDrawer="visibleSourcesFilter = false" 
    :visibleDrawer="visibleSourcesFilter"></sources-filters-side-drawer>
    <discover-source-filter-drawer :visibleSourceFilterDrawer="visibleSourceFilterDrawer"
        @closeSourcesFilterDrawer="closeSourceFilterDrawer">
    </discover-source-filter-drawer>
</template>

<script>
import SourcesFiltersSideDrawer from "./sourcesFilters/SourcesFiltersSideDrawer.vue";
import DiscoverSourceFilterDrawer from "./sourcesFilters/DiscoverSourceFilterDrawer.vue";



export default {
    components: {
        SourcesFiltersSideDrawer,
        DiscoverSourceFilterDrawer,
    },
    data() {
        return {
            visibleSourcesFilter: false,
            selectSortDate: true,
            selectSortRelevance: false,
            visibleSortFilter: false,
            visibleSortDrawer: false,
            visibleSourceFilterDrawer: false,
            articlesPerPage: 10,
        }
    },
    async mounted() {
        await this.emitter.on("closeFilterSideDrawer", (val) => {
            this.visibleSourcesFilter = val;
            this.visibleSortFilter = false;
        });
        await this.emitter.on("closeTopicBar", (val) => {
            this.visibleSortFilter = val;
        });
        await this.emitter.on("show-sort-drawer", (val) => {
            this.visibleSortDrawer = true;
        });
        await this.emitter.on("show-sources-drawer", (val) => {
          this.visibleSourceFilterDrawer = true;
        });
        await this.emitter.on("show-sources-side-drawer", (val) => {
          this.visibleSourcesFilter = true;
        });
    },
    computed: {
        results() {
            return {
                value: this.$store.getters["discover/getArticleTotalResults"].value,
                relation: this.$store.getters["discover/getArticleTotalResults"].relation,
            };
        },
        pageNumber() {
            return this.$store.getters["discover/getCurrenPageNumber"];
        },
        totalPages() {
            if (this.results.value) {
                return Math.ceil(this.results.value / this.articlesPerPage);
            }
            return 0;
        },
        articlesLength() {
            return this.$store.getters['discover/getTotalArticles'];
        },
        getSortFilter() {
            return this.$store.getters["discover/getSortFilter"];
        },
        filtersCounts() {
            return this.$store.getters['discover/getFilterCounts'];
        },
        searchTopic() {
            const topic = this.$store.getters["discover/getSearchedTopic"];
            const keyword = this.$store.getters["discover/getSearchQuery"];
            return keyword ? keyword : topic;

        },
    },
    methods: {
        openSourcesFilters() {
            this.visibleSourcesFilter = !this.visibleSourcesFilter;
            this.visibleSortFilter = false;
        },
        openSortFilters() {
            this.visibleSortFilter = !this.visibleSortFilter;
        },
        showSortDrawer() {
            this.visibleSortDrawer = true
        },
        closeSortDrawer() {
            this.visibleSortDrawer = false
        },
        applySortFilter(value) {
          this.$store.commit("discover/SET_SORT_FILTER", value);
          this.$store.commit("discover/SET_INSIGHTS_PAGE_OFFSET", 0);
          this.$store.commit("discover/SET_ARTICLES_PAGE_OFFSET", 0);
            this.searchNewsArticles();
            this.searchInsightsArticles();
            if (value === 'recency') {
                this.selectSortDate = true;
                this.selectSortRelevance = false;
            } else if (value === 'relevancy') {
                this.selectSortRelevance = true;
                this.selectSortDate = false;
            }
            this.closeSortDrawer();
        },
        // openSourcesDrawer() {
        //     this.visibleSourceFilterDrawer = true;
        // },
        closeSourceFilterDrawer() {
            this.visibleSourceFilterDrawer = false;
        },
        async searchNewsArticles() {
            await this.$store.dispatch("discover/searchArticles");
        },
        async searchInsightsArticles() {
          await this.$store.dispatch("discover/searchInsights");
        }
    }
}
</script>



<style lang="scss">
.apply-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.3rem 2rem;
    border-top: 1px solid $color-dark-grey-5;
    width: 100%;
    background-color: $color-white;
    @include respond(phone-x-small) {
      padding: 2rem 3rem;
    }
    &__left {
      .title {
        display: flex;
        align-items: center;
        width: fit-content;
        @include respond(laptop-small) {
          flex-direction: column;
          align-items: flex-start;
          p {
            margin-left: 0 !important;
          }
        }
        @include respond(phone-x-small) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          p {
            margin-left: 0 !important;
            text-align: left;
          }
        }
        p {
          margin-bottom: 0;
          margin-left: 0.5rem;
          font-family: $font-primary-medium;
          font-size: 1.6rem;
          line-height: 2.8rem;
          @include respond(phone-x-small) {
            font-size: 2rem;
          }
          span {
            color: $color-primary;
            margin-left: 0.5rem;
          }
        }
      }
    }
    &__right {
      display: flex;
      align-items: center;
      margin-left: auto;
      .pagination-container {
        @include respond(tab-port) {
          display: none;
        }
        &__pagination {
          margin-top: 0;
          padding-right: 2.6rem;
          @include respond(phone-x-small) {
            padding-right: 0;
          }
          .btn {
            font-size: 1.3rem;
            .icon-forward {
              font-size: 1.3rem;
              color: $color-black;
            }
            .prev {
              margin-right: 1rem;
              margin-left: 0;
            }
            .next {
              margin-left: 1rem;
              margin-right: 0;
            }
          }
          .counter {
            font-size: 1.3rem;
          }
        }
      }
      .buttons {
        display: flex;
        align-items: center;
        @include respond(tab-port) {
          order: 1;
          display: none;
        }
        .filter-btn {
          display: flex;
          align-items: center;
          padding: 0.7rem 2.4rem;
          border-radius: 100px !important;
          border: none;
          outline: none;
          background-color: #ebebeb;
          cursor: pointer;
          height: 4.4rem;
          p {
            color: $color-black;
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            margin-bottom: 0;
            margin: 0 1rem;
          }
          svg {
            fill: $color-black;
            width: 1.6rem;
          }
          img {
            width: 1.6rem;
          }
        }
        .sort-container {
          position: relative;
          .sort {
            display: flex;
            align-items: center;
            padding: 0.7rem 2.4rem;
            border-radius: 100px !important;
            border: none;
            outline: none;
            background-color: #ebebeb;
            cursor: pointer;
            height: 4.4rem;
            p {
              color: $color-black;
              font-size: 1.6rem;
              font-family: $font-primary-medium;
              margin-bottom: 0;
              margin: 0 1rem;
            }
            svg {
              fill: $color-black;
              width: 1.6rem;
            }
            img {
              width: 1.6rem;
            }
          }
          .sort-dropdown {
            position: absolute;
            width: 100%;
            background-color: $color-white;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            border-end-end-radius: 0.5rem;
            border-end-start-radius: 0.5rem;
            p {
              margin-bottom: 0;
              padding: 1rem 2.2rem !important;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-size: 1.6rem;
              font-family: $font-primary-medium;
              line-height: normal;
              border-bottom: 1px solid $color-dark-grey-5;
              cursor: pointer;
              &:last-child {
                border-bottom: none;
              }
            }
            .select-sort {
              background-color: $color-primary;
              color: $color-white;
            }
          }
        }
        .filter-apply-btn {
          margin: 0 2rem;
        }
        .filter-active {
          background-color: #0a1438;
          p {
            color: $color-white;
          }
          svg {
            fill: $color-white;
          }
        }
      }
      .mobile-buttons-container {
        display: none;
        @include respond(tab-port) {
          display: block;
        }
        .mobile-buttons {
          display: flex;
          align-items: center;
          .filter-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px !important;
            border: none;
            outline: none;
            background-color: #ebebeb;
            cursor: pointer;
            height: 4.4rem;
            width: 7rem;
            svg {
              width: 1.8rem;
            }
            @include respond(phone-x-small) {
              height: 6.3rem;
              width: 10rem;
              svg {
                width: 2.9rem;
              }
            }
          }
          .sort-container {
            position: relative;
            .sort {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100px !important;
              border: none;
              outline: none;
              background-color: #ebebeb;
              cursor: pointer;
              height: 4.4rem;
              width: 7rem;
              img {
                width: 1.8rem;
              }
              @include respond(phone-x-small) {
                height: 6.3rem;
                width: 10rem;
                img {
                  width: 2.9rem;
                }
              }
            }
          }
          .filter-apply-btn {
            margin: 0 2rem;
          }
          .filter-active {
            background-color: #0a1438;
            svg {
              fill: $color-white;
            }
          }
        }
      }
      
    }
  }
</style>