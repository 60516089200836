<template>
  <div class="request-news-source-container">
    <h3 class="title">Request additional sources if they are not listed to better tailor your content.</h3>
    <a-form layout="vertical">
      <a-form-item label-align="left">
        <label for="message">Type URL <button class="see-all-sources-btn" @click="toggleSeeAllSourcesModal()">See all sources</button></label>
        <!-- <div class="https-field"> -->
          <!-- <p>https://</p> -->
          <TagifyKeywords 
            placeholder="Enter source urls of your choice"
            @fetchKeywords="fetchKeywords"
            module='sources'
          />
          <!-- <a-input
          placeholder="type source"
          required
          style="width: 100%"
          v-model:value="requestSource"
          @focusout="handleFocusout">
          </a-input> -->
        <!-- </div> -->
        <p v-if="urlValidate" class="error">URL is not valid</p>
        <p v-if="fieldErr" class="error">This field is required.</p>
        <p class="error" v-if="requestSourceErr">{{ requestSourceErr }}</p>
      </a-form-item>
      <a-form-item label-align="left">
        <label for="message">Type Message <span> (Optional)</span></label>
        <a-textarea
          rows="3"
          v-model:value="requestMessage"
        ></a-textarea>
      </a-form-item>
      <a-button
        class="btn-primary request-btn"
        @click="submitNewsSourceRequest"
        :loading="loading"
      >
        Submit
      </a-button>
    </a-form>
    <all-news-sources-modal
      :visible="seeAllSourcesModalToggle"
      @closeModal="toggleSeeAllSourcesModal"
      :isListing="true"
      :isResearch="isResearch"
   >
   </all-news-sources-modal>
  </div>
</template>


<script>
import TagifyKeywords from '@/components/PdbComponents/TagifyKeywords.vue';
import AllNewsSourcesModal from '@/components/BaseComponents/sourcesFilters/AllNewsSourcesModal.vue'
import {mapGetters} from "vuex"
export default {
  props: {
    visibleModal: Boolean,
    requestType: String,
  },
  components:{
    TagifyKeywords,
    AllNewsSourcesModal
  },
  watch: {
    visibleModal() {
      this.requestNewsSourceModal = this.visibleModal;
    },
  },
  mounted(){
      this.emitter.on('clearFields', (val) => {
        this.requestSource = "";
        this.urlValidate = false;
        this.fieldErr = false
        this.requestSourceErr = "";
        this.requestSource = [];
        this.requestMessage = "";
        this.emitter.emit('clearFilters')
      })
  },
  data() {
    return {
      requestNewsSourceModal: false,
      requestSourceErr: "",
      requestSource: [],
      requestMessage: "",
      urlValidate: false,
      fieldErr: false,
      loading: false,
      showMessageModal: false,
      message: {},
      seeAllSourcesModalToggle: false
    };
  },
  emits: ["closeModal" ],
  computed:{
    ...mapGetters({
      selectedModule: 'discover/selectedModule'
    }),
    isResearch(){
      return this.selectedModule === 'Trending News' ? false : true
    }
  },
  methods: {
    closeRequestNewsSourceModal() {
      this.requestSourceErr = "";
      this.requestSource = "";
      this.requestMessage = "";
      this.emitter.emit('clearFilters')
      this.$emit("closeModal");
    },
    handleFocusout() {
      this.requestSource = this.requestSource.replace(/^https?:\/\//, "");
      0;
      if (
        /^(?:www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/.test(this.requestSource)
      ) {
        this.urlValidate = false;
        this.fieldErr = false;
      } else if(/^(?:www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+[/]/.test(this.requestSource)){
        this.urlValidate = false;
        this.fieldErr = false;
      }
      else {
        this.urlValidate = true;
        this.fieldErr = false;
      }
    },
    async submitNewsSourceRequest() {
      if (this.requestSource.length === 0) {
        this.fieldErr = true;
        this.urlValidate = false;
        return false;
      }
      if (this.urlValidate == true) {
        return false;
      }
      try {
        this.loading = true;
        this.requestSourceErr = "";
        const httpUrlRegex =  /^https?:\/\//
        const withoutPrefixUrl = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}(:\d+)?(\/[^\s]*)?$/
        const payload = this.requestSource.map(source=>({
          link: withoutPrefixUrl.test(source) ? 'https://www.'+source : httpUrlRegex.test(source) ? source : 'https://'+source,
          message: this.requestMessage,
          type: this.requestType
        }))
        const response = await this.$store.dispatch(
          "discover/requestNewsSource",
          payload
        );
        if (response.status === 201) {
          this.closeRequestNewsSourceModal();
          this.emitter.emit('closeNewsSourceModal')
          this.$showToast({message:"News source requested successfully.", type:'success'})
          this.loading = false;
        }
      } catch (error) {
        if (error.response?.data?.non_field_errors && error.response?.data?.non_field_errors.length > 0)
          this.requestSourceErr = error.response?.data?.non_field_errors[0]
        else
          this.requestSourceErr = error.response?.data[0]
      }finally{
        this.loading = false;
      }
    },
    fetchKeywords(urls){
      // console.log("URLs", urls)
      this.requestSource = urls
    },
    toggleSeeAllSourcesModal(){
      this.seeAllSourcesModalToggle = !this.seeAllSourcesModalToggle
    }
  },
};
</script>


<style lang="scss">
.request-news-source-container {
  .ant-form {
    .ant-form-item {
      margin-bottom: 1.7rem;
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            label {
              margin-bottom: 0.7rem;
              display: inline-flex;
              align-items: center;
              justify-content: space-between;
              font-size: 1.4rem;
              gap: 1rem;
              font-family: $font-primary;
              line-height: normal;
              width: 100%;
              span {
                opacity: 0.6;
                margin-right: auto;
              }
              .see-all-sources-btn {
                display: inline-block;
                height: fit-content;
                width: max-content;
                line-height: normal;
                font-size: 1.4rem;
                color: $color-primary;
                border: none;
                outline: none;
                font-family: $font-primary;
                background-color: transparent;
                cursor: pointer;
              }
            }
            .ant-input {
              margin-bottom: 0;
            }
            .tagify {
              min-height: 4rem;
              margin-bottom: 0;
              width: 100%;
              padding: 0;
              font-size: 1.4rem;
              font-family: $font-primary;
              outline: none;
              border: 1px solid $color-dark-grey-5;
              color: $color-black;
              &__input {
                padding: 1.2rem 1.5rem;
                height: 100%;
                font-size: inherit;
              }
            }
            // .https-field {
            //   display: flex;
            //   align-items: center;
            //   width: 100%;
            //   border: 1px solid $color-dark-gray-4;
            //   border-radius: 0.5rem;
            //   padding: 0 1rem;
            //   height: 4rem;
            //   background-color: $color-white;
            //   p {
            //     margin-bottom: 0;
            //     font-size: 1.4rem;
            //     font-family: $font-primary;
            //   }
            //   input {
            //     border: none;
            //     padding: 0;
            //     box-shadow: none;
            //   }
            // }
            // input {
            //   height: 100%;
            //   margin-bottom: 1.5rem;
            //   width: 100%;
            //   padding: 0 1.5rem;
            //   font-size: 1.4rem;
            //   font-family: $font-primary;
            //   outline: none;
            //   border: 1px solid $color-dark-gray-4;
            //   &::placeholder {
            //     opacity: 0.4;
            //     color: $color-black;
            //   }
            // }
            textarea {
              width: 100%;
              resize: none;
              box-shadow: none;
              padding: 1rem;
              font-size: 1.4rem !important;
              font-family: $font-primary;
              outline: none;
              border: 1px solid $color-dark-gray-4;
              color: $color-black;
            }
            .error {
              font-size: 1.2rem;
              margin-top: 0.3rem;
              margin-bottom: 0;
              font-family: $font-primary;
            }
          }
        }
      }
    }
    .request-btn {
      position: relative !important;
      border-radius: 0.5rem !important;
      width: 10rem;
      height: 3.6rem;
      font-family: "Roboto-medium";
      font-size: 1.4rem;
      background-color: $color-primary;
      margin-left: auto;
      margin-top: 3rem;
      border: none;
      color: $color-white !important;
      display: block;
      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        color: $color-white;
      }
      .anticon {
        .anticon-spin {
          font-size: 2rem !important;
        }
      }
    }
  }
  .title {
    font-size: 1.3rem;
    line-height: 1.7rem;
    margin-bottom: 1.5rem;
    font-family: $font-primary-medium;
    padding-right: 0.5rem;
  }
  
  .disable-submit-btn {
    pointer-events: none;
    background-color: gray;
    opacity: 0.5;
  }
}
</style>
