<template>
  <tagify-keywords
    placeholder="Enter keywords of your choice"
    @fetchKeywords="fetchKeywords"
    :isSubmitted="isSubmit"
    :nullify="emptyTagifyInput"
    :prevTags="prevTags"
  ></tagify-keywords>
</template>

<script>
import TagifyKeywords from "../../PdbComponents/TagifyKeywords.vue";

export default {
  components: {
    TagifyKeywords,
  },
  props: {
    type: String,
    prevTags:{
      type:Array,
      default: () => ([])
    }
  },
  data() {
    return {
      keywords: null,
    };
  },
  methods: {
    fetchKeywords(val) {
      this.keywords = val;
      if (this.type === "include") {
        this.$emit("fetchInlcude", val);
      } else {
        this.$emit("fetchExclude", val);
      }
    },
  },
};
</script>
<style lang="scss">
.tagify {
  &__tag {
    margin: 0.5rem 0 0.5rem 0.5rem;
  }
}
</style>
