<template>
    <a-drawer :title="false" placement="bottom" :closable="false" :visible="visibleDrawer"
        class="request-source-drawer">
        <div class="request-source-container">
            <div class="request-source-container__header">
                <p>{{ title }}</p>
                <i class="icon icon-add_plus" @click="closeDrawer()"></i>
            </div>
            <div class="request-source-container__body">
                <request-news-source :requestType="type"></request-news-source>
            </div>
        </div>
    </a-drawer>
</template>

<script>
import RequestNewsSource from './RequestNewsSource.vue';

export default{
    props: {
        visibleDrawer: Boolean,
        type: String,
        title: String
    },
    components: {
        RequestNewsSource
    },
    emits: ['closeRequestSourceDrawer'],
    methods: {
        closeDrawer(){
            this.$emit('closeRequestSourceDrawer', false);
        }
    }
}
</script>

<style lang="scss">
.request-source-drawer {
  .ant-drawer-content-wrapper {
    height: auto !important;
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-body {
          height: 100%;
          padding: 3.6rem 3.2rem;
          .request-source-container {
            height: 100%;
            &__header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 2rem;
              p {
                font-size: 2.8rem;
                font-family: $font-primary-bold;
                line-height: normal;
                margin-bottom: 0;
                color: $color-black;
                opacity: 1;
              }
              .icon {
                transform: rotate(45deg);
                display: block;
                cursor: pointer;
                font-size: 2.5rem;
                opacity: 1;
                color: $color-black;
              }
            }
            &__body {
              .request-news-source-container {
                .title {
                  font-size: 2.1rem;
                  font-family: $font-primary-regular;
                  line-height: 3.3rem;
                  margin-bottom: 2.9rem;
                  max-width: 85%;
                  color: $color-black;
                  opacity: 1;
                }
                .ant-form {
                  .ant-row {
                    margin-bottom: 2rem;
                    .ant-col {
                      .ant-form-item-control-input {
                        .ant-form-item-control-input-content {
                          label {
                            font-size: 2.2rem;
                            color: $color-black;
                            opacity: 1;
                            margin-bottom: 1.5rem;
                            line-height: normal;
                            font-family: $font-primary;
                          }
                          .tagify {
                            font-size: 2.1rem;
                            height: 6.2rem;
                            &__input {
                              padding: 1.7rem 1.8rem;
                            }
                          }
                          // .https-field {
                          //   height: 6.3rem;
                          //   padding: 0 1.6rem;
                          //   p, input {
                          //     font-size: 2.1rem;
                          //     font-family: $font-primary-regular;
                          //   }
                          // }
                          textarea {
                            height: 10.4rem;
                            padding: 1.6rem;
                            font-size: 2.1rem !important;
                            font-family: $font-primary-regular;
                          }
                          .error {
                            font-size: 2.1rem;
                            font-family: $font-primary-regular;
                            line-height: 2.5rem;
                            margin-top: 0.5rem;
                          }
                        }
                      }
                    }
                  }
                  .request-btn {
                    width: 100%;
                    height: 6.8rem;
                    font-size: 2.1rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>